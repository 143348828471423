import httpClient from "./httpClient";

const END_POINT = "/api/operations";
const getAllOperations = () => httpClient.get(END_POINT);
const searchOperation = (name) => httpClient.get("api/search/operations/" + name)
const addOperation = operation => httpClient.post(END_POINT, operation);
const updateOperation = operation => httpClient.put(END_POINT + '/' + operation.id, { name: operation.name });
const deleteOperation = (id) => httpClient.delete(END_POINT + "/" + id);
const getOperationsByDossierName = (name) => httpClient.get("api/dossier/" + name + "/operations")
export { getAllOperations, searchOperation, addOperation, updateOperation, deleteOperation, getOperationsByDossierName };
