<template>
  <div>
    <div class="input-container">
      <text-input
        prepend-inner-icon="mdi-magnify"
        @keyup="handleSearch"
        class="ml-5 mt-5"
        id="search"
        v-model="search"
        placeholder="Nom de role"
      />
    </div>
    <div class="container-cards mb-16">
      <div v-for="role in filteredRoles" :key="role.id">
        <card :className="'mt-5 border-radius'" :width="'600px'">
          <template v-slot:card-title> Role : {{ role.name }} </template>
          <template v-slot:card-content>
            <Select
              :value="role.operations"
              :options="operations"
              :roleId="role.id"
              @updateOperation="valueChanged"
            />
            <div class="button-container">
              <Button
                :disabled="!role.isShowButtonSave"
                action_name="Sauvegarder"
                color="secondary"
                @clicked="updateOperation"
              >
              </Button>
            </div>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { getAllRolesOperations, updateRole } from "@/services/roles.api"
import { TextInput, Button } from "@/components/base"
import Card from "@/components/base/card"
import Select from "@/components/base/select"
import { getAllOperations } from "@/services/operations.api"
export default {
  components: { Button, Card, TextInput, Select },
  data() {
    return {
      roles: [],
      filteredRoles: [],
      search: "",
      operations: [],
      updateObject: {}
    }
  },
  async created() {
    await this.iniziale()
    getAllOperations(this.pageNumber).then((response) => {
      this.operations = response.data.map((el) => {
        return { code: el.id, name: el.name }
      })
    })
  },
  methods: {
    async iniziale() {
      const resp = await getAllRolesOperations()
      this.roles = resp.data.map((el) => {
        return {
          ...el,
          isShowButtonSave: false,
          operations: el.operations.map((el) => {
            return { code: el.id, name: el.name }
          })
        }
      })
      this.filteredRoles = this.roles
    },
    handleSearch() {
      if (this.search) {
        this.filteredRoles = this.roles.filter((role) =>
          role.name.toUpperCase().includes(this.search.toUpperCase())
        )
      } else {
        this.filteredRoles = this.roles
      }
    },
    valueChanged(data) {
      const index = this.roles.findIndex((el) => el.id === data.role)
      if (index !== undefined) {
        this.roles[index].isShowButtonSave = true
        this.filteredRoles[index].isShowButtonSave = true
      }
      this.updateObject = { ...data, operations: data.value.map((el) => el.code) }
    },
    async updateOperation() {
      const response = await updateRole(this.updateObject)
      if (response.status === 200) {
        await this.iniziale()
        const index = this.roles.findIndex((el) => el.id === this.updateObject.role)
        if (index !== undefined) {
          this.roles[index].isShowButtonSave = false
          this.filteredRoles[index].isShowButtonSave = false
        }
      }
    }
  }
}
</script>
<style scoped>
.container-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.input-container {
  width: 25%;
  margin: 0 auto;
}
.button-container {
  text-align: center;
  margin-top: 15px;
}
.border-radius {
  border-radius: 15px;
}
</style>
