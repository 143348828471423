<template>
  <v-card class="mx-auto pa-5" :class="className" :width="width">
    <v-card-title class="justify-content-center">
      <slot name="card-title"></slot>
    </v-card-title>
    <v-card-text>
      <v-container>
        <slot name="card-content"></slot>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "card",
  props: {
    title: {
      String,
      required: false,
      default: "Title Of Card"
    },
    width: {
      Number,
      required: false,
      default: 357
    },
    className: {
      String,
      default: "mt-16",
      required: false
    }
  }
}
</script>
<style>
/*.container {*/
/*  border: 1px solid green;*/
/*}*/

/*.row {*/
/*  border: 1px solid red;*/
/*}*/

/*.col {*/
/*  border: 1px solid blue;*/
/*}*/
.card-title {
  font-family: "Lato", serif;
  color: #003d42;
  font-size: 34px;
  display: block;
}
.v-card {
  background: #fff;
  border: 1px solid #fff;
  box-shadow: 0px 3px 26px rgba(0, 0, 0, 0.16) !important;
}
.v-card__actions {
  padding: 0 !important;
  /*padding-right: 16px;*/
  /*padding-left: 16px;*/
}
</style>
