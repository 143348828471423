<template>
  <div>
    <label class="typo__label mb-5">Opérations:</label>
    <multiselect
      @select="update"
      @remove="update"
      searchable
      :showLabels="false"
      :optionHeight="5"
      :close-on-select="false"
      v-model="initialValue"
      label="name"
      track-by="code"
      placeholder="selectionner opération(s)"
      :options="options"
      :multiple="true"
      :taggable="true"
      :showPointer="false"
    ></multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect"

export default {
  components: {
    Multiselect
  },
  props: {
    options: [],
    roleId: {
      default: 0
    },
    value: {
      default: []
    }
  },
  data() {
    return {
      initialValue: []
    }
  },
  created() {
    this.initialValue = this.value
  },
  methods: {
    update() {
      setTimeout(() => {
        this.$emit("updateOperation", { role: this.roleId, value: this.initialValue })
      }, 500)
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.v-application ul {
  padding-left: 0;
}
.container {
  padding: 0px;
}
</style>
